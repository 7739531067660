
import { helperMixin, saveAndUpdate } from "@/mixins";
import { FleetDetail, FleetSave } from "@/models/fleet";
import { defineComponent } from "vue";
import fleetHttp from "@/http/fleet";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import { Tonnage, DefaultCondition, SelectionType } from "@/enums";
import selectionStore from "@/store/selection";
import SelectionListModal from "@/components/Selection/SelectionListModal.vue";

export default defineComponent({
  components: {
    TeleportToContentFooter,
    BaseFormGroup,
    BaseFormApp,
    SelectionListModal
  },
  mixins: [helperMixin, saveAndUpdate<FleetSave, FleetDetail>(fleetHttp())],
  data() {
    return {
      SelectionType,
      DefaultCondition,
      Tonnage,
      selectionListTitle: "",
      selectionAddTitle: "",
      selectionEditTitle: "",
      selectionListType: null as SelectionType | null,
      showSelectionListModal: false
    };
  },
  computed: {
    selectionStore,
    showCommunicationDetail() {
      return (
        this.selectionStore.findById(
          this.form.communication_id,
          this.selectionStore.state.fleet_communication
        )?.name === this.DefaultCondition.FleetCommunicationNeedDetail
      );
    }
  },
  methods: {
    onMounted() {
      return this.selectionStore.fetch();
    },
    handleShowSelectionModal(
      addTitle: string,
      editTitle: string,
      type: SelectionType
    ) {
      this.selectionAddTitle = addTitle;
      this.selectionEditTitle = editTitle;
      this.selectionListType = type;
      this.showSelectionListModal = true;
    },
    setForm() {
      this.form.ship_name = this.data?.ship_name;
      this.form.call_sign = this.data?.call_sign;
      this.form.ship_flag = this.data?.ship_flag;
      this.form.IMO_number = this.data?.IMO_number;
      this.form.type_of_ship = this.data?.type_of_ship;
      this.form.classification_society = this.data?.classification_society;
      this.form.ship_registry = this.data?.ship_registry;
      this.form.type_of_hull = this.data?.type_of_hull;
      this.form.loa = this.data?.loa;
      this.form.breadth_moulded = this.data?.breadth_moulded;
      this.form.depth_moulded = this.data?.depth_moulded;
      this.form.keel_to_masterhead = this.data?.keel_to_masterhead;
      this.form.free_board_summer = this.data?.free_board_summer;
      this.form.dwt_summer_draft = this.data?.dwt_summer_draft;
      this.form.displacement_summer = this.data?.displacement_summer;
      this.form.tonnage = this.data?.tonnage;
      this.form.communication_id = this.data?.communication?.id;
      this.form.communication_detail_id = this.data?.communication_detail?.id;
      this.form.minimum_temperature = this.data?.minimum_temperature;
      this.form.discharge_pump = this.data?.discharge_pump;
      this.form.propulsion = this.data?.propulsion;
      this.form.max_continous_rating = this.data?.max_continous_rating;
      this.form.propeller = this.data?.propeller;
      this.form.commersial_speed = this.data?.commersial_speed;

      this.form.owner_id = this.data?.owner_id;

      this.form.owner_address = this.data?.owner?.fleet_person?.address || null;

      this.form.owner_phone_number =
        this.data?.owner?.fleet_person?.phone_number || null;

      this.form.owner_fax_number =
        this.data?.owner?.fleet_person?.fax_number || null;

      this.form.manager_operator_id = this.data?.manager_operator_id;

      this.form.mo_address =
        this.data?.manager_operator?.fleet_person?.address || null;
      this.form.mo_phone_number =
        this.data?.manager_operator?.fleet_person?.phone_number || null;
      this.form.mo_fax_number =
        this.data?.manager_operator?.fleet_person?.fax_number || null;
      this.form.mo_telex_number =
        this.data?.manager_operator?.fleet_person?.telex_number || null;
      this.form.mo_email =
        this.data?.manager_operator?.fleet_person?.email || null;

      this.form.builder_hull = this.data?.builder_hull;
    }
  }
});
