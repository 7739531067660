
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import SelectionSaveModal from "@/components/Selection/SelectionSaveModal.vue";
import { baseModal, helperMixin, selectionSaveModal } from "@/mixins";
import { defineComponent, PropType } from "vue";
import selectionStore from "@/store/selection";
import ConfirmModal from "@/components/ConfirmModal.vue";

export default defineComponent({
  components: {
    BaseButtonPrimary,
    SelectionSaveModal,
    BaseModal,
    BaseTable,
    BaseTableRow,
    BaseTableColumn,
    ConfirmModal
  },
  mixins: [helperMixin, baseModal, selectionSaveModal],
  props: {
    editModalTitle: {
      type: String,
      required: true
    },
    addModalTitle: {
      type: String,
      required: true
    },
    type: {
      type: Object as PropType<import("@/enums").SelectionType>,
      required: true
    }
  },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    selectionStore
  },
  methods: {
    handleSearch() {}
  }
});
